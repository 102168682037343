import subscriptionApi from '@/http/subscription'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface LifetimeModel {
  lifetimeSubsMeta?: Object
}

const initialState: LifetimeModel = {
  lifetimeSubsMeta: {},
}

function createExtraActions() {
  return {
    fetchLifetimeSubsMeta: createAsyncThunk(
      `/lifetime-subscriptions-meta`,
      async (_payload, thunkAPI) => {
        const dispatch = thunkAPI.dispatch
        let res = await subscriptionApi.lifetimeSubsMeta({})
        dispatch(
          lifetimeActions.setLifetimeSubsMeta(res),
        )
      },
    ),
  }
}
const extraActions = createExtraActions()

export const lifetimeSlice = createSlice({
  name: 'lifetime',
  initialState,
  reducers: {
    resetState(state): any {
      Object.assign(state, initialState)
    },
    setLifetimeSubsMeta(state, { payload }: PayloadAction<LifetimeModel>): any {
      state.lifetimeSubsMeta = payload;
    },
  },
})

export default lifetimeSlice.reducer
export const lifetimeActions = {
  ...lifetimeSlice.actions,
  ...extraActions,
}