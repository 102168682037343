import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const ThemeModeKey = 'themeMode'

interface MagnifyExportSettings {
  resolution?: number,
  output?: string,
  fps?: any,
  videoBitrate?: number
}
interface IAppState {
  themeMode: 'light' | 'dark'
  leftBoxWidth: number
  openRestoreVersion: boolean
  materiaBoxWidth: number
  hideMaterialTab: boolean
  aspectRatio: string
  videoPlatform: string
  timelineRect: DOMRect | null
  mouseInTimeline: boolean
  nearestTransitionTrackIndex: number
  nearestTransitionClipIndex: number
  illusto_loader: boolean
  magnifyRouteBackUrl: any
  magnifyExportSettings: MagnifyExportSettings
  showMaterialPanel: boolean  // show/hide left panel
}

const initialState: IAppState = {
  themeMode: localStorage[ThemeModeKey] || 'dark',
  leftBoxWidth: 0,
  openRestoreVersion: false,
  hideMaterialTab: false,
  materiaBoxWidth: 295,
  aspectRatio: '16:9',
  videoPlatform: 'youtube',
  timelineRect: null,
  mouseInTimeline: false,
  nearestTransitionTrackIndex: -1,
  nearestTransitionClipIndex: -1,
  illusto_loader: false,
  magnifyRouteBackUrl: null,
  magnifyExportSettings: {},
  showMaterialPanel: true,   // show/hide left panel
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateThemeMode(state, { payload }: PayloadAction<'light' | 'dark'>) {
      state.themeMode = payload
      localStorage[ThemeModeKey] = payload
    },
    updateLeftBoxWidth(state, { payload }: PayloadAction<number>) {
      state.leftBoxWidth = payload
    },
    updateMateriaBoxWidth(state, { payload }: PayloadAction<number>) {
      state.materiaBoxWidth = payload
    },
    updateOpenRestoreVersion(state, { payload }: PayloadAction<boolean>) {
      state.openRestoreVersion = payload
    },
    updateHideMaterialTab(state, { payload }: PayloadAction<boolean>) {
      state.hideMaterialTab = payload
    },
    updateAspectRatio(state, { payload }: PayloadAction<string>) {
      state.aspectRatio = payload
    },
    updateVideoPlatform(state, { payload }: PayloadAction<string>) {
      state.videoPlatform = payload
    },
    updateTimelineRect(state, { payload }: PayloadAction<DOMRect>) {
      state.timelineRect = payload
    },
    updateMouseInTimeline(state, { payload }: PayloadAction<boolean>) {
      state.mouseInTimeline = payload
    },
    updateNearestTransitionTrackIndex(
      state,
      { payload }: PayloadAction<number>,
    ) {
      state.nearestTransitionTrackIndex = payload
    },
    updateNearestTransitionClipIndex(
      state,
      { payload }: PayloadAction<number>,
    ) {
      state.nearestTransitionClipIndex = payload
    },
    setIllustoLoader(state, { payload }: PayloadAction<boolean>) {
      state.illusto_loader = payload
    },
    updateMagnifyRouteBackUrl(state, { payload }: PayloadAction<any>) {
      state.magnifyRouteBackUrl = payload
    },
    updateMagnifyExportSettings(state, { payload }: PayloadAction<MagnifyExportSettings>) {
      state.magnifyExportSettings = payload
    },
    updateShowMaterialPanel(state, { payload }: PayloadAction<boolean>) {
      state.showMaterialPanel = payload
    },
  },
})

export const appActions = { ...appSlice.actions }

export default appSlice.reducer
