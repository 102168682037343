import subscriptionApi from '@/http/subscription'
import checkIfLifetimePlan from '@/utils/lifetimePlan'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PlansModel {
  plans: Object
}

const initialState: PlansModel = {
  plans: {},
}

function createExtraActions() {
  return {
    fetchPlansDetails: createAsyncThunk(
      `/plans-details`,
      async (_payload, thunkAPI) => {
        const dispatch = thunkAPI.dispatch
        let res = await subscriptionApi.getPaymentPlans({})
        res = res.filter((el: any) => ((el.unique_code.indexOf('SUMO_') == -1) && (el.unique_code.indexOf('account_lifetime') == -1)))
        let obj: { monthly: Object[]; annual: Object[]; lifetime: Object[] } = {
          monthly: [],
          annual: [],
          lifetime: [],
        }
        for (let i of res) {
          if (checkIfLifetimePlan(i.unique_code)) {
            obj['lifetime'].push(i);
            continue
          }
          if (i.unique_code.toLowerCase() == 'free') {
            obj['monthly'].push(i)
            obj['annual'].push(i)
            continue
          }
          if (i.plan_interval.toLowerCase() == 'monthly') {
            obj['monthly'].push(i)
          } else if (i.plan_interval.toLowerCase() == 'annual') {
            obj['annual'].push(i)
          }
        }
        dispatch(
          PlansActions.setPlansDetails({
            plans: obj,
          }),
        )
      },
    ),
  }
}
const extraActions = createExtraActions()

export const PlansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    resetState(state): any {
      Object.assign(state, initialState)
    },
    setPlansDetails(state, { payload }: PayloadAction<PlansModel>): any {
      state.plans = payload.plans
    },
  },
})

export default PlansSlice.reducer
export const PlansActions = {
  ...PlansSlice.actions,
  ...extraActions,
}