import store from '@/store'
import { useAppSelector } from '@/store/hooks'
import { userActions } from '@/store/user'
import { getToken } from '@/utils/auth'
import EventBus from '@/utils/EventBus'
import axios, { AxiosRequestConfig } from 'axios'
import { remoteApi } from './constant'
import { getApiHost } from './network'

// create an axios instance
const service = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
})

const obtainNewAccessToken = () => service.post(remoteApi().refresh_token, {}, {});

const refreshExpiredTokenClosure = () => {
  let isCalled = false;
  let runningPromise: Promise<any> | undefined = undefined;
  return () => {
    if (isCalled) {
      return runningPromise;
    } else {
      isCalled = true;
      runningPromise = obtainNewAccessToken();
      runningPromise.then(res => {
        isCalled = false
        return res;
      })
      return runningPromise;
    }
  };
};

export const refreshExpiredToken = refreshExpiredTokenClosure();


// request interceptor
service.interceptors.request.use(
  (config: any) => {
    // do something before request is sent
    const {
      user: { token },
    } = store.getState()
    if (config?.url?.indexOf(getApiHost('illusto2Backend')) !== -1) {
      config.withCredentials = true;
    }
    if (token) {
      if (import.meta.env.VITE_CONFIG_NAME === 'meishe') {
        config.headers.token = getToken()
      }else if(config?.url?.indexOf(getApiHost('magnify2')) !== -1){
        config.headers.Authorization = localStorage.getItem('magnifi-auth');
      } else {
        config.headers.Authorization = 'Bearer ' + getToken()
      }
    }

    if (config.version) {
      config.headers.version = config.version
    }

    return config
  },
  error => {
    // do something with request error
    console.error(error) // for debug
    return Promise.reject(error)
  },
)

function getCookie(key: string) {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const [cookieKey, cookieValue] = cookie.trim().split('=');
    if (cookieKey === key) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    return response.data
  },
  async (error) => {
    const originalRequest = error.config;

    const dispatch = store.dispatch
    const isQuotasOrDemoEditorUrl = (originalRequest.url.indexOf(getApiHost('demoEditor')) !== -1) || (originalRequest.url.indexOf(getApiHost('quotas')) !== -1)
    // if the status code is 401, it is judged as un authenticated.
    console.error(error)
    if (error.response.status === 401 &&
      originalRequest.url === remoteApi().refresh_token) {
      EventBus.$emit('customMessages', {
        message: 'Please login first',
        type: 'warning',
      })
      console.warn('Please login first')
      dispatch(userActions.resetState())
      document.cookie = 'refresh-token=expired; Domain=.illusto.com; expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Secure; SameSite=None';
      location.href = '/login'
    } else if ((error?.response?.status === 401) ||
      (error?.response?.status === 403 && isQuotasOrDemoEditorUrl)) {
        if(originalRequest.url.indexOf(getApiHost('magnify2')) !== -1){
          //return to dashboard;
          const { magnifyRouteBackUrl } = useAppSelector(state => state.app)
          window.location.href = magnifyRouteBackUrl.href;
          return;
        }
      // generate new token using refresh token
      const refreshTokenres = await refreshExpiredToken();
      const newAccessToken = refreshTokenres?.data?.token
      if (newAccessToken) {
        dispatch(userActions.setToken(newAccessToken));
        localStorage.setItem('Authorization', newAccessToken);
        if (import.meta.env.VITE_CONFIG_NAME === 'meishe') {
          originalRequest.headers.token = newAccessToken;
        } else {
          originalRequest.headers.Authorization = 'Bearer ' + newAccessToken;
        }
        return service(originalRequest);
      } else {
        EventBus.$emit('customMessages', {
          message: 'Please login first',
          type: 'warning',
        })
        console.warn('Please login first')
        dispatch(userActions.resetState())
        document.cookie = 'refresh-token=expired; Domain=.illusto.com; expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Secure; SameSite=None';
        location.href = '/login'
      }
    }
    console.error('Network Error')
    return Promise.reject(error)
  },
)

export const get = ({
  url,
  headers,
  completeResp,
}: {
  url: string
  headers: AxiosRequestConfig
  completeResp?: boolean
}) => {
  return service.get(url, headers).then(res => {
    return completeResp ? res : res.data
  })
}

export const getV2 = ({
  url,
  headers,
  completeResp,
}: {
  url: string
  headers: AxiosRequestConfig
  completeResp?: boolean
}) => {
  return service.get(url, headers).then(res => {
    return res;
  })
}

export const post = ({
  url,
  data,
  headers,
  onUploadProgress,
}: {
  url: string
  data: any
  headers?: any
  onUploadProgress?: any
}) => {
  return service.post(url, data, { headers, onUploadProgress }).then(res => {
    return res;
  })
}

export const delet = ({
  url,
  headers,
}: {
  url: string
  headers: AxiosRequestConfig
}) => {
  return service.delete(url, headers).then(res => {
    return res.data
  })
}
