import subscriptionApi from '@/http/subscription'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PurchaseModel {
  planId?: number,
  type?: string
  unique_code?: string,
  planName?: string,
  planPrice?: string,
  planInterval?: string,
  currency?: string,
  amountToBePaid?: string,
  currentPlanProrataAmount?: string,
  currentPlanRemainingDays?: string,
  invoiceEndDate?: string, isDowngrade?: boolean | null, isPromoCodeApplied?: string, isUpgrade?: string, newPlanProrataAmount?: string, newPlanRemainingDays?: string, price?: string, promoCodeDetails?: string, promoCodeDiscount?: string, prorataDiscount?: string, refundable?: string,
  error?: boolean,
  errorMessage?: string
}

const initialState: PurchaseModel = {
  type: '',
  unique_code: '',
  planName: '',
  planPrice: '',
  planInterval: '',
  currency: '',
  amountToBePaid: '',
  currentPlanProrataAmount: '',
  currentPlanRemainingDays: '',
  invoiceEndDate: '', isDowngrade: null, isPromoCodeApplied: '', isUpgrade: '', newPlanProrataAmount: '', newPlanRemainingDays: '', price: '', promoCodeDetails: '', promoCodeDiscount: '', prorataDiscount: '', refundable: '',
  error: false,
  errorMessage: ''
}

function createExtraActions() {
  return {
    applyPromoCode: createAsyncThunk(
      `/apply-promocode`,
      async ({ planId, promoCode }: any, thunkAPI) => {
        const dispatch = thunkAPI.dispatch

        /*  =
          await subscriptionApi.applyPromoCode({ planId, promoCode }) */

        const { data, status, error }: any = await subscriptionApi.applyPromoCode({ planId, promoCode })
        if (status === 'success') {
          const { planId, amountToBePaid, currentPlanProrataAmount, currentPlanRemainingDays
            , invoiceEndDate, isDowngrade, isPromoCodeApplied, isUpgrade, newPlanProrataAmount, newPlanRemainingDays, price, promoCodeDetails, promoCodeDiscount, prorataDiscount, refundable } = data;
          dispatch(
            purchaseActions.setPromoCodeDetails({
              planId, amountToBePaid, currentPlanProrataAmount, currentPlanRemainingDays
              , invoiceEndDate, isDowngrade: isDowngrade ? true : false, isPromoCodeApplied, isUpgrade, newPlanProrataAmount, newPlanRemainingDays, price, promoCodeDetails, promoCodeDiscount, prorataDiscount, refundable
            }),
          )
          dispatch(purchaseActions.resetError({}))
        } else {
          dispatch(
            purchaseActions.setError({
              errorMessage: 'Invalid Code'
            }),
          )
        }


      },
    ),
  }
}
const extraActions = createExtraActions()

export const purchaseSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resetPurchaseState(state): any {
      Object.assign(state, initialState)
    },
    setPurchaseDetails(
      state,
      { payload }: PayloadAction<PurchaseModel>,
    ): any {
      state.type = payload.type || '';
      state.unique_code = payload.unique_code || '';
      state.planName = payload.planName || '';
      state.planPrice = payload.planPrice || '';
      state.planInterval = payload.planInterval || '';
      state.currency = payload.currency || '';
      if (payload?.planId) state.planId = payload.planId;
      if (payload?.amountToBePaid) state.amountToBePaid = payload.amountToBePaid;
      if ('isDowngrade' in payload) state.isDowngrade = payload.isDowngrade;
    },
    setPromoCodeDetails(
      state,
      { payload }: PayloadAction<any>,
    ): any {
      state.planId = payload.planId || null;
      state.amountToBePaid = payload.amountToBePaid || '';
      state.currentPlanProrataAmount = payload.currentPlanProrataAmount || '';
      state.currentPlanRemainingDays = payload.currentPlanRemainingDays || '';
      state.invoiceEndDate = payload.invoiceEndDate || '';
      state.isDowngrade = payload.isDowngrade;
      state.isPromoCodeApplied = payload.isPromoCodeApplied || '';
      state.isUpgrade = payload.isUpgrade || '';
      state.newPlanProrataAmount = payload.newPlanProrataAmount || '';
      state.newPlanRemainingDays = payload.newPlanRemainingDays || '';
      state.price = payload.price || '';
      state.promoCodeDetails = payload.promoCodeDetails || '';
      state.promoCodeDiscount = payload.promoCodeDiscount || '';
      state.prorataDiscount = payload.prorataDiscount || '';
      state.refundable = payload.refundable || '';
    },
    setStateFromLocalStorage(
      state,
      { payload }: PayloadAction<any>,
    ): any {
      if(!('isDowngrade' in payload)) payload.isDowngrade = false;
      state = Object.assign(state, payload)
      
    },
    setError(
      state,
      { payload }: PayloadAction<PurchaseModel>,
    ): any {
      state.error = true;
      state.errorMessage = payload.errorMessage || '';
      state.isPromoCodeApplied = '';
    },
    resetError(
      state,
      { payload }: PayloadAction<PurchaseModel>,
    ): any {
      state.error = false;
      state.errorMessage = '';
    },
  },
})

export default purchaseSlice.reducer
export const purchaseActions = {
  ...purchaseSlice.actions,
  ...extraActions,
}
