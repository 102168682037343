import materialApi from '@/http/material'
import Enum from '@/utils/enum'
import {
  checkAndInstallAssetFromIndexDB,
  saveAndInstallPackage,
  saveAssetToIndexDB,
} from '@/utils/nvDB/assets'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '.'

interface IMaterialState {
  defaultMusicLyrics: string
  defaultFontfamily: string
  fontNameObj: { [key: string]: any }
  fonts: any[]
  invalidAssetIds: string[]
}

const initialState: IMaterialState = {
  defaultMusicLyrics: '',
  defaultFontfamily: '',
  fontNameObj: {},
  fonts: [],
  invalidAssetIds: [],
}

const installMusicLyrics = createAsyncThunk(
  'material/installMusicLyrics',
  async (_payload, thunkAPI) => {
    const dispatch = thunkAPI.dispatch
    const res = await materialApi.material_list({
      type: Enum.mType.captionstyle,
      page: 0,
      pageSize: 20,
      category: Enum.captionType.musicLyrics,
    })
    for (let i = 0; i < res.materialList.length; i++) {
      const url = res.materialList[i].packageUrl
      const licenseUrl = res.materialList[i].licenseUrl
      const packageName = url.substring(url.lastIndexOf('/') + 1)
      try {
        const filePath = (await checkAndInstallAssetFromIndexDB({
          licenseUrl,
          key: packageName,
        })) as string
        if (i === 0) {
          dispatch(
            materialActions.updateDefaultMusicLyrics(
              filePath.substring(0, filePath.indexOf('.')),
            ),
          )
        }
      } catch (error) {
        try {
          const packageData = await axios.get(url, {
            responseType: 'arraybuffer',
          })
          await saveAssetToIndexDB(
            packageName,
            new Uint8Array(packageData.data),
          )
          if (licenseUrl) {
            const licData = await axios.get(licenseUrl, {
              responseType: 'arraybuffer',
            })
            await saveAssetToIndexDB(
              packageName.split('.')[0] + '.lic',
              new Uint8Array(licData.data),
            )
          }
          const filePath = (await checkAndInstallAssetFromIndexDB({
            key: packageName,
          })) as string
          if (i === 0) {
            dispatch(
              materialActions.updateDefaultMusicLyrics(
                filePath.substring(0, filePath.indexOf('.')),
              ),
            )
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
  },
)

const loadFonts = createAsyncThunk(
  'material/loadFonts',
  async (_payload, thunkAPI) => {
    try {
      const dispatch = thunkAPI.dispatch
      const publicList = await loadPublicFontList()
      let combinedList = []
      if (import.meta.env.VITE_CONFIG_NAME === 'meishe') {
        const privateList = await loadPrivateFontList()
        combinedList = [...publicList, ...privateList]
      } else {
        combinedList = [...publicList]
      }
      const list = combinedList.filter(
        item => item.displayName !== 'NotoColorEmoji',
      )
      dispatch(materialActions.updateFontsLoaded(list))
    } catch (error) {
      console.error('Font initialization failed', error)
    }
  },
)

async function loadPublicFontList() {
  let map: any = {}
  let skip_count = 0;
  let fontList = []
  let pageNum = 1
  let res = await materialApi.material_list({
    type: Enum.mType.font,
    SortByName:0,
    page: 0,
    pageSize: 1000,
  })

  for (let i of res.materialList) {
    if (map[i.stringValue] == undefined) {
      map[i.stringValue] = true;
      fontList.push(i);
    }
  }
  let current_list = fontList;
  // while (current_list.length > 0) {
  //   console.log('inside')
  //   res = await materialApi.material_list({
  //     type: Enum.mType.font,
  //     page: pageNum++,
  //     pageSize: 100,
  //   })
  //   console.log(res)
  //   current_list = res.materialList
  //   console.log('size2', current_list.length)
  //   for (let i of res.materialList) {
  //     if (map[i.stringValue] == undefined) {
  //       map[i.stringValue] = true;
  //       fontList.push(i);
  //     }
  //   }
  //   // fontList = fontList.concat(res.materialList)
  // }
  return fontList
}

async function loadPrivateFontList() {
  let fontList = []
  let pageNum = 1
  let res = await materialApi.material_private_list({
    type: Enum.mType.font,
    page: 0,
    pageSize: 100,
  })
  fontList = res.elements
  while (res.materialCount > fontList.length) {
    res = await materialApi.material_private_list({
      type: Enum.mType.font,
      pageNum: pageNum++,
      pageSize: 100,
    })
    fontList = fontList.concat(res.elements)
  }
  return fontList
}

const saveAndInstallDefaultFonts = createAsyncThunk<
  string,
  void,
  { state: RootState }
>('material/saveAndInstallDefaultFonts', async (_payload, thunkAPI) => {
  try {
    const dispatch = thunkAPI.dispatch
    const { fonts, fontNameObj } = thunkAPI.getState().material
    let index = fonts.length - 1
    let f = fonts.find((f: any,i:number) => {
      if (f.stringValue.indexOf("illusto_default") !== -1) {
        index = i;
        return true
      }
      return false;
    })
    let DEFAULT_INDEX = index;
    let familyNameFromStringValue
    if (fonts.length) {
      if (!fonts[0].hasInstalled) {
        let familyName = await saveAndInstallPackage(fonts[DEFAULT_INDEX])
        if (familyName.indexOf('Noto Sans CJK') !== -1) {
          familyName = 'Noto Sans CJK SC'
        }
        const emojiFont = fonts.find(
          (item: any) => item.displayName === 'NotoColorEmoji',
        )
        if (emojiFont) {
          await saveAndInstallPackage(emojiFont)
        }
        familyNameFromStringValue = fonts[DEFAULT_INDEX].stringValue
        if (familyNameFromStringValue.split(' [')[0] !== familyName) {
          dispatch(
            materialActions.updateFontNameObj({
              key: familyNameFromStringValue,
              value: familyName,
            }),
          )
        }
        dispatch(materialActions.updateLoadedFont(familyNameFromStringValue))
        if (fontNameObj.hasOwnProperty(familyNameFromStringValue)) {
          familyNameFromStringValue = fontNameObj[familyNameFromStringValue]
        }
        dispatch(
          materialActions.updateDefaultFontfamily(familyNameFromStringValue),
        )
      } else {
        familyNameFromStringValue = fonts[0].stringValue
        dispatch(
          materialActions.updateDefaultFontfamily(familyNameFromStringValue),
        )
      }
    }
    return familyNameFromStringValue
  } catch (error) {
    console.error('Font initialization failed', error)
  }
})

export const materialSlice = createSlice({
  name: 'material',
  initialState,
  reducers: {
    updateDefaultMusicLyrics(state, { payload }: PayloadAction<string>) {
      state.defaultMusicLyrics = payload
    },
    updateDefaultFontfamily(state, { payload }: PayloadAction<string>) {
      state.defaultFontfamily = payload
    },
    updateLoadedFont(state, { payload }: PayloadAction<string>) {
      const f = state.fonts.find(item => item.stringValue === payload)
      if (f) {
        f.hasInstalled = true
      }
    },
    updateFontsLoaded(state, { payload }: PayloadAction<any[]>) {
      state.fonts = payload.map((item: any) => ({
        ...item,
        hasInstalled: false,
      }))
    },
    updateFontNameObj(state, { payload }: PayloadAction<any>) {
      const { key, value } = payload
      state.fontNameObj[key] = value
    },
    updateInvalidAssetIds(state, { payload }: PayloadAction<string[]>) {
      state.invalidAssetIds = payload
    },
  },
})

export const materialActions = {
  installMusicLyrics,
  loadFonts,
  saveAndInstallDefaultFonts,
  ...materialSlice.actions,
}

export default materialSlice.reducer
