// @ts-nocheck
const ChromeBrowserMinCompleteVersionSupported = '75.0.3770.142'
const SafariMinSupportedVersion = '15.5'

export default function checkBrowserSupport() {
  let isEquipmentSupport = false,
    isBrowserSupport = false,
    isBrowserVersionSupport = false,
    isBrowserParamsSupport = true,
    isBrowserModeSupport = true, // Indicates whether the current browsing mode supports [Firefox privacy mode does not support]
    updateParamsIndex = 0,
    version = 'an unknown version',
    browser = 'An unknown browser',
    OS = 'an unknown OS',
    dataMobileOS = [
      'Android',
      'iPhone',
      'SymbianOS',
      'Windows Phone',
      'iPad',
      'iPod',
    ],
    dataOS = [
      {
        string: navigator.platform,
        subString: 'Win',
        identity: 'Windows',
      },
      {
        string: navigator.platform,
        subString: 'Mac',
        identity: 'Mac',
      },
      {
        string: navigator.userAgent,
        subString: 'iPhone',
        identity: 'iPhone/iPod',
      },
      {
        string: navigator.platform,
        subString: 'Linux',
        identity: 'Linux',
      },
    ],
    dataBrowser = [
      {
        string: navigator.userAgent,
        subString: 'Chrome',
        identity: 'Chrome',
      },
      {
        string: navigator.userAgent,
        subString: 'OmniWeb',
        versionSearch: 'OmniWeb/',
        identity: 'OmniWeb',
      },
      {
        string: navigator.vendor,
        subString: 'Apple',
        identity: 'Safari',
        versionSearch: 'Version',
      },
      {
        prop: window.opera,
        identity: 'Opera',
      },
      {
        string: navigator.vendor,
        subString: 'iCab',
        identity: 'iCab',
      },
      {
        string: navigator.vendor,
        subString: 'KDE',
        identity: 'Konqueror',
      },
      {
        string: navigator.userAgent,
        subString: 'Firefox',
        identity: 'Firefox',
      },
      {
        string: navigator.vendor,
        subString: 'Camino',
        identity: 'Camino',
      },
      {
        // for newer Netscapes (6+)
        string: navigator.userAgent,
        subString: 'Netscape',
        identity: 'Netscape',
      },
      {
        string: navigator.userAgent,
        subString: 'MSIE',
        identity: 'Explorer',
        versionSearch: 'MSIE',
      },
      {
        string: navigator.userAgent,
        subString: 'Gecko',
        identity: 'Mozilla',
        versionSearch: 'rv',
      },
      {
        // for older Netscapes (4-)
        string: navigator.userAgent,
        subString: 'Mozilla',
        identity: 'Netscape',
        versionSearch: 'Mozilla',
      },
    ],
    is360 = false,
    versionSearchString = ''

  function created() {
    detectBrowser()
    isEquipmentSupport = detectPCOS()

    //other browsers
    if (!isElseBrowser()) {
      isBrowserSupport = false
    } else {
      isBrowserSupport = true
      if (isOpera()) {
        updateParamsIndex = 3
        if (browser === 'Chrome' && isVersionHeigherThanDefault()) {
          isBrowserVersionSupport = true
          isBrowserParamsSupport =
            isSupportSharedArrayBuffer() && isSupportWebassembly()
        } else {
          isBrowserVersionSupport = false
        }
      } else if (isFirefox()) {
        if (browser === 'Chrome') {
          updateParamsIndex = 2
          if (isVersionHeigherThanDefault()) {
            isBrowserVersionSupport = true
            isBrowserParamsSupport =
              isSupportSharedArrayBuffer() && isSupportWebassembly()
          } else {
            isBrowserVersionSupport = false
          }
        } else {
          updateParamsIndex = 2
          if (version >= 57) {
            // Here, the official version of Firefox 72, even if the website has set the corresponding header and set the corresponding options through about:config, it still cannot be used normally
            if (version >= 72) {
              isBrowserSupport = false
            } else {
              isBrowserVersionSupport = true
              isBrowserParamsSupport =
                isSupportSharedArrayBuffer() && isSupportWebassembly()
            }
          } else {
            isBrowserVersionSupport = false
          }
          if (version >= 80) {
            isBrowserSupport = true
            isBrowserVersionSupport = true
            isBrowserParamsSupport =
              isSupportSharedArrayBuffer() && isSupportWebassembly()
          }
        }
        // Check if Firefox is in private mode
        //TODO for the time being [Firefox privacy mode does not support indexDB], did not find a better way to check
        const checkDBName = 'check-browser-open-mode-test'
        const db = indexedDB.open(checkDBName)
        db.onerror = () => {
          isBrowserModeSupport = false
        }
        indexedDB.deleteDatabase(checkDBName)
      } else if (isEdge() || isIE() || isIE11()) {
        updateParamsIndex = 4
        if (browser === 'Chrome' && isVersionHeigherThanDefault(79)) {
          isBrowserVersionSupport = true
          isBrowserParamsSupport =
            isSupportSharedArrayBuffer() && isSupportWebassembly()
        } else {
          isBrowserVersionSupport = false
        }
      } else if (isSafari()) {
        // isBrowserSupport = false
        updateParamsIndex = 5
        if (isVersionHeigherThanDefault(SafariMinSupportedVersion)) {
          isBrowserVersionSupport = true
          isBrowserParamsSupport =
            isSupportSharedArrayBuffer() && isSupportWebassembly()
        } else {
          isBrowserVersionSupport = false
        }
      } else {
        if (
          window.clientInformation &&
          window.clientInformation.languages &&
          window.clientInformation.languages.length >= 2
        ) {
          updateParamsIndex = 0
          if (browser === 'Chrome' && isVersionHeigherThanDefault()) {
            isBrowserVersionSupport = true
            isBrowserParamsSupport =
              isSupportSharedArrayBuffer() && isSupportWebassembly()
          } else {
            isBrowserVersionSupport = false
          }
        } else {
          is360 = true
          updateParamsIndex = 1
          if (browser === 'Chrome' && isVersionHeigherThanDefault()) {
            isBrowserVersionSupport = true
            isBrowserParamsSupport =
              isSupportSharedArrayBuffer() && isSupportWebassembly()
          } else {
            isBrowserVersionSupport = false
          }
        }
      }
    }

    const videoReaderCount = isSupportVideoEncoder() ? 15 : 10
    localStorage.setItem('meishesdk-max-video-reader-count', videoReaderCount)
  }

  // useEffect(() => {
  created()

  // }, [])

  function isSupportVideoEncoder() {
    return typeof VideoEncoder !== 'undefined'
  }

  function isVersionHeigherThanDefault(
    defaultVersion = ChromeBrowserMinCompleteVersionSupported,
  ) {
    const defaultDigitArr = defaultVersion.split('.')
    const currentDigitArr = String(version).split('.')
    if (defaultDigitArr.length && currentDigitArr.length) {
      if (Number(currentDigitArr[0]) > Number(defaultDigitArr[0])) {
        return true
      } else {
        if (version.localeCompare(defaultVersion) >= 0) {
          return true
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }

  function isSupportSharedArrayBuffer() {
    return typeof SharedArrayBuffer !== 'undefined'
  }

  function isSupportWebassembly() {
    if (isIE() || isIE11()) {
      return false
    }
    return typeof WebAssembly !== 'undefined'
  }

  // Determine whether the current browser is available
  function isElseBrowser() {
    let ua = navigator.userAgent.toLocaleLowerCase()
    let browserType = null
    let itBrowserNoSupport = true
    if (ua.match(/ubrowser/) != null) {
      browserType = 'UC'
      itBrowserNoSupport = false
    } else if (ua.match(/baidubrowser/) != null) {
      browserType = 'baidu'
      itBrowserNoSupport = false
    } else if (ua.match(/metasr/) != null) {
      browserType = 'sougou'
      itBrowserNoSupport = false
    } else if (
      ua.match(/tencenttraveler/) != null ||
      ua.match(/qqbrowse/) != null
    ) {
      browserType = 'QQ'
      itBrowserNoSupport = true
    } else if (ua.match(/maxthon/) != null) {
      browserType = 'aoyou'
      itBrowserNoSupport = false
    } else if (ua.match(/chrome/) != null) {
      browserType = 'chrome'
      itBrowserNoSupport = true
    }
    return itBrowserNoSupport
  }

  function detectPCOS() {
    let userAgentInfo = navigator.userAgent
    let flag = true
    for (let v = 0; v < dataMobileOS.length; v++) {
      if (userAgentInfo.indexOf(dataMobileOS[v]) > 0) {
        OS = dataMobileOS[v]
        flag = false
        break
      }
    }
    if (flag) {
      OS = searchString(dataOS) || 'an unknown OS'
    }
    return flag
  }

  function detectBrowser() {
    browser = searchString(dataBrowser) || 'An unknown browser'
    version =
      searchVersion(navigator.userAgent) ||
      searchVersion(navigator.appVersion) ||
      'an unknown version'
  }

  function searchVersion(dataString) {
    let index = dataString.indexOf(versionSearchString)
    if (index === -1) return
    if (versionSearchString === 'Chrome') {
      let versionStringToEnd = dataString.substring(
        index + versionSearchString.length + 1,
      )
      let versionStringOnly = versionStringToEnd.split(' ')[0]
      return versionStringOnly
    } else {
      return dataString.substring(index + versionSearchString.length + 1)
    }
  }

  function searchString(data) {
    for (let i = 0; i < data.length; i++) {
      let dataString = data[i].string
      let dataProp = data[i].prop
      versionSearchString = data[i].versionSearch || data[i].identity
      if (dataString) {
        if (dataString.indexOf(data[i].subString) !== -1) {
          return data[i].identity
        }
      } else if (dataProp) {
        return data[i].identity
      }
    }
  }

  function isChrome() {
    return (
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
    )
  }

  function isFirefox() {
    return typeof InstallTrigger !== 'undefined'
  }

  function isEdge() {
    let isIE = /*@cc_on!@*/ false || !!document.documentMode
    return !isIE && !!window.StyleMedia
  }

  function isOpera() {
    return (
      (!!window.opr && !!opr.addons) ||
      !!window.opera ||
      navigator.userAgent.indexOf(' OPR/') >= 0
    )
  }

  function isIE() {
    let userAgent = navigator.userAgent
    return (
      userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1
    )
  }

  function isIE11() {
    let userAgent = navigator.userAgent
    return (
      userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
    )
  }

  function isSafari() {
    return (
      /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
    )
  }

  return {
    isEquipmentSupport,
    isBrowserSupport,
    isBrowserVersionSupport,
    isBrowserParamsSupport,
    isBrowserModeSupport,
  }
}
