import userApi from '@/http/user'
import { getUserId } from '@/utils/auth';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface CoachMarksModel {
  run: boolean;
  stepIndex?: number;
  showTour?: boolean;
  isCompleted?: boolean;
}

const initialState: CoachMarksModel = {
  run: false,
  stepIndex: 0,
  showTour: false,
  isCompleted: false,
}

function createExtraActions() {
  return {
    fetchTutorialFlag: createAsyncThunk(
      `/get-tutorial-flag`,
      async (_, thunkAPI) => {
        const dispatch = thunkAPI.dispatch
        const response: any = await userApi.getEditorTutorialStatus();
        if (response?.status === 'success') {
          const { tutorial_completion } = response.data;
          dispatch(
            coachMarkActions.setCoachmarkCompletion(tutorial_completion),
          )
        }
      },
    ),
    completeTutorial: createAsyncThunk(
      `/set-tutorial-flag`,
      async (_, thunkAPI) => {
        const dispatch = thunkAPI.dispatch
        const { data, status, error }: any = await userApi.setEditorTutorialStatus({
          completionStatus: true,
        });
        if (status === 'success') {
          dispatch(
            coachMarkActions.setCoachmarkCompletion(true),
          )
        }
      },
    ),
  }
}
const extraActions = createExtraActions()

export const coachMarkSlice = createSlice({
  name: 'coachMarks',
  initialState,
  reducers: {
    resetState(state): any {
      Object.assign(state, { run: false, stepIndex: 0, showTour: false })
    },
    setCoachmarksMeta(
      state,
      { payload }: PayloadAction<CoachMarksModel>,
    ): any {
      state.run = payload.run || false;
      state.stepIndex = payload.stepIndex || 0;
      state.showTour = payload.showTour || false;
    },
    stepForward(state): any {
      state.stepIndex = (state.stepIndex || 0) + 1;
    },
    stepBack(state): any {
      state.stepIndex = (state.stepIndex || 0) - 1;
    },
    setCoachmarkCompletion(
      state,
      { payload }: PayloadAction<boolean>,
    ): any {
      state.isCompleted = payload || false;
      state.showTour = !payload;
      if (!payload) {
        // @ts-ignore
        dataLayer.push({
          event: "tutorial_begin",
          name: "coach_marks",
          user_id: getUserId(),
          watch_type: "first_watch"
        })
      }
    },
  },
})

export default coachMarkSlice.reducer
export const coachMarkActions = {
  ...coachMarkSlice.actions,
  ...extraActions,
}
