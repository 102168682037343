import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { InfoRounded } from "@mui/icons-material";
import { isFromMagnify } from "../magnifyEnterprise";
// import { environment, RELEASE_HASH } from "../../config/envConfig";
const environment = window.$nvsConfig.env;
// const RELEASE_HASH = window.$nvsConfig.RELEASE_HASH;
const RELEASE_HASH = import.meta.env.VITE_RELEASE_HASH;

export const initDataDog = () => {
  if(isFromMagnify()){
    initDataDogRum();
    initDataDogLogs();
  }
};

export const initDataDogRum = () => {
  datadogRum.init({
    applicationId: '219d86f5-2f2f-4e05-88f7-84ca01a3522f',
    clientToken: 'pube0992b063c883e630618ddd541d2eeac',
    site: import.meta.env.VITE_DATADOG_SITE || 'datadoghq.eu',
    service: 'vv-illusto-studio-v1',
    env: environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: RELEASE_HASH,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
};

export const initDataDogLogs = () => {
  datadogLogs.init({
    clientToken: "pub424d7f4f0a562c7ff3514552607b77e7",
    site: "datadoghq.eu",
    service: "vv-illusto-studio-v1",
    env: environment,
    version: RELEASE_HASH,
    forwardConsoleLogs: "all",
  });
};

export const setDataDogUserProperties = (userDetails: any) => {
  if (userDetails) {
    datadogLogs.setGlobalContextProperty("user", {
      userid: userDetails?._id,
      name: userDetails?.name,
      email: userDetails?.email,
    });

    datadogRum.setUser({
      id: userDetails?._id,
      name: userDetails?.name,
      email: userDetails?.email,
    });
  }
};

export const addRenderingErrorToDataDogRum = (error: any, info: any) => {
  console.log(error,info)
  const renderingError = new Error(error.message);
  renderingError.name = `ReactRenderingError`;
  renderingError.stack = info.componentStack;
  renderingError.cause = error;

  datadogRum.addError(renderingError);
};
