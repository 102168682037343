import { getLocaleInfo } from '@/i18n'
import store from '@/store'
import { audioTypes, imageTypes, TLRules, videoTypes } from '@/utils/constant'
import Enum from '@/utils/enum'
import axios from 'axios'
import cloneDeepWith from 'lodash/cloneDeepWith'
import {
  AllClip,
  AllTrack,
  AudioClip,
  CaptionClip,
  TimelineData,
  TimelineVideoFxClip,
  VideoClip,
} from './ProjectData'

function unitFormat(number: number) {
  let numberString = ''
  if (number < 10) {
    numberString += '0'
  }
  return numberString + number.toString()
}

function unitFormat2(number: number) {
  let numberString = ''
  return numberString + number.toString()
}

/**
 * Duration convert
 * @param value
 * @returns {string}
 */
export function secToTimeDefault(value: number) {
  let time = value
  let sec = parseInt(time as any) / 1000
  let min
  let hour = parseInt((sec / 3600) as any)
  if (hour > 0) {
    sec %= 3600
    min = parseInt((sec / 60) as any)
    if (min > 0) {
      sec %= 60
    }
  } else {
    min = parseInt((sec / 60) as any)
    if (min > 0) {
      sec %= 60
    }
  }
  return (
    unitFormat(hour) +
    ':' +
    unitFormat(min) +
    ':' +
    unitFormat(<any>sec.toFixed(0))
  )
}

export function buildTimestampFromString(time: string) {
  if (typeof time !== 'string') return
  const timeArr = time.split(':')
  if (timeArr.length === 4) {
    const hour = Number(timeArr[0])
    const min = Number(timeArr[1])
    const sec = Number(timeArr[2])
    const frame = Number(timeArr[3])
    // microsecond
    return (
      (Number(hour * 3600) + Number(min * 60) + Number(sec) + frame / 25) *
      1000000
    )
  }
  if (timeArr.length === 3) {
    const hour = Number(timeArr[0])
    const min = Number(timeArr[1])
    const sec = Number(timeArr[2])
    // microsecond
    return (Number(hour * 3600) + Number(min * 60) + Number(sec)) * 1000000
  } else {
    return 0
  }
}

export function buildStringFromTimestamp(
  value: number,
  options = { withMilli: false, withFrame: false },
) {
  const { withMilli = false, withFrame = false } = options
  let time = value / 1000000
  if (time < 1) {
    time = 0
  }
  let sec = parseInt(time.toString())
  let min
  let hour = parseInt((sec / 3600).toString())
  if (hour > 0) {
    sec %= 3600
    min = parseInt((sec / 60).toString())
    if (min > 0) {
      sec %= 60
    }
  } else {
    min = parseInt((sec / 60).toString())
    if (min > 0) {
      sec %= 60
    }
  }
  if (withMilli) {
    let milli: number | string = (value % 1000000) / 1000
    if (milli < 1) {
      milli = '000'
    } else if (milli < 10) {
      milli = '00' + milli
    } else if (milli < 100) {
      milli = '0' + milli
    }
    return (
      unitFormat(hour) +
      ':' +
      unitFormat(min) +
      ':' +
      unitFormat(Number(sec.toFixed(0))) +
      ',' +
      milli
    )
  } else if (withFrame) {
    const frame = unitFormat(Math.round((time % 1) * 25))
    return (
      unitFormat(hour) +
      ':' +
      unitFormat(min) +
      ':' +
      unitFormat(Number(sec.toFixed(0))) +
      ':' +
      frame
    )
  } else {
    return (
      unitFormat(hour) +
      ':' +
      unitFormat(min) +
      ':' +
      unitFormat(Number(sec.toFixed(0)))
    )
  }
}
/**
- If the duration is >= 10 hr, show it in this format hh:mm:ss
- If the duration is < 10 hr & >= 1hr, show it in this format h:mm:ss
- If the duration is < 1 hr & >= 10 m, show it in this format mm:ss
- If the duration is < 10 m & >= 1 m, show it in this format m:ss
- If the duration is < 1 m & >= 10 s, show it in this format 0:ss
- If the duration is < 10s, show it in this format 0:0s
*/
export function buildStringFromTimestamp2(value: number) {
  let time = value / 1000000
  if (time < 1) {
    time = 0
  }
  let sec = parseInt(time.toString())
  let min
  let hour = parseInt((sec / 3600).toString())
  if (hour > 0) {
    sec %= 3600
    min = parseInt((sec / 60).toString())
    if (min > 0) {
      sec %= 60
    }
  } else {
    min = parseInt((sec / 60).toString())
    if (min > 0) {
      sec %= 60
    }
  }
  if (hour > 0) {
    return (
      unitFormat2(hour) +
      ':' +
      unitFormat(min) +
      ':' +
      unitFormat(Number(sec.toFixed(0)))
    )
  } else if (min > 0) {
    return unitFormat2(min) + ':' + unitFormat(Number(sec.toFixed(0)))
  } else {
    return '0:' + unitFormat(Number(sec.toFixed(0)))
  }
}

/**
 * timestamp conversion
 * @param time
 * @returns {string}
 */
export function buildTimeStringFromTimestamp(time: number) {
  let date
  const timeStr = String(time)
  // If the timestamp is 10 digits, you need *1000, if the timestamp is 13 digits, you don’t need to multiply by 1000
  if (timeStr.length === 10) {
    date = new Date(time * 1000)
  } else if (timeStr.length === 13) {
    date = new Date(time)
  } else {
    return ''
  }
  let Y = date.getFullYear() + '-'
  let M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  let D = unitFormat(date.getDate())
  let h = date.getHours() + ':'
  let m = unitFormat(date.getMinutes())
  return Y + M + D + ' ' + h + m
}

export function buildTimestampFromDate(value: string) {
  const hh = new Date(value).getHours()
  const mm = new Date(value).getMinutes()
  const ss = new Date(value).getSeconds()
  // microsecond
  return (hh * 3600 + mm * 60 + ss * 1) * 1000000
}

export function buildDatefromTimestamp(ts: number) {
  // microsecond timestamp
  const m = buildStringFromTimestamp(ts)
  if (m) {
    // 00:00:01
    const hh = m.substring(0, 2)
    const mm = m.substring(3, 5)
    const ss = m.substring(6, 8)
    return new Date(1970, 1, 1, Number(hh), Number(mm), Number(ss))
  } else {
    return ''
  }
}

export function buildCompareFormat(value: number) {
  return (value / 1000000) | 0
}

export function getWidthByDuration(
  duration: number,
  pixelPerMicrosecond?: number,
) {
  const {
    project: { timelineRulerRule },
  } = store.getState()
  duration = duration / 1000000
  if (pixelPerMicrosecond) {
    return duration * 25 * pixelPerMicrosecond
  } else {
    const { rulingSpace, tipSpace } = TLRules[timelineRulerRule]
    return duration * 25 * (rulingSpace / tipSpace)
  }
}

export function getDurationByWidth(width: number) {
  const {
    project: { timelineRulerRule },
  } = store.getState()
  const { rulingSpace, tipSpace } = TLRules[timelineRulerRule]
  return (((tipSpace / rulingSpace) * width) / 25) * 1000000
}

// When dragging resources from the resource list, the width does not change with the scale
export function getDefaultWidthByDuration(duration: number) {
  duration = duration / 1000000
  const a = TLRules[8]
  const t = a.rulingSpace / a.tipSpace
  return t * duration * 25
}
export function formattedData(time: number, startTime: string) {
  time /= 25
  const arr = startTime.split(':')
  if (arr.length === 3) {
    const h = Number(arr[0]) * 1
    const m = Number(arr[1]) * 1
    const s = Number(arr[2]) * 1
    time += h * 60 * 60 + m * 60 + s
  }
  const hour = parseInt((time / (60 * 60)).toString())
  const min = parseInt((time / 60).toString()) - hour * 60
  const sec = parseInt(time.toString()) - hour * 3600 - min * 60
  const f: (num: number) => any = num => (num > 9 ? num : '0' + num)
  return `${f(hour) % 24}:${f(min)}:${f(sec)}:${f(Math.round((time % 1) * 25))}`
}

// Limit val to max, min
export function checkRange(val: number, [min, max]: number[]) {
  return Math.max(Math.min(val, max), min)
}

export function getDurationFromM3u8(m3u8String: string) {
  let duration = 0
  m3u8String.replace(/MEISHE-DURATION:(\d+)/, (($0: any, $1: any) => {
    duration = $1
  }) as any)
  return duration * 1000
}

export function blob2Json(blob: Blob) {
  return new Promise((resolve, reject) => {
    if (Object.prototype.toString.call(blob) === '[object Blob]') {
      const reader = new FileReader()
      reader.onload = function () {
        resolve(JSON.parse(reader.result as string))
      }
      reader.onerror = reject
      reader.readAsText(blob, 'utf-8')
    } else if (typeof blob === 'string') {
      resolve(JSON.parse(blob))
    } else {
      resolve(blob)
    }
  })
}

function computeDuration(m3u8: string) {
  let duration = 0
  m3u8.replace(/#EXTINF:(\d+.\d+)/g, ($0, $1) => {
    duration += $1 * 1000
    return $0
  })
  return duration
}

export function generateUUID() {
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] as any & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'
  const uuid = s.join('')
  return uuid
}

export function getImageSizeAndStep(projectId: number) {
  const streamingContext = nvsGetStreamingContextInstance()
  let { width, height, rotation } = streamingContext.getAVFileInfo(
    `/m3u8/${projectId}.m3u8`,
    0,
  ).videoStreamInfo
  if (rotation === 1 || rotation === 3) {
    ;[width, height] = [height, width]
  }
  let clipView = document.getElementById('clip-view')
  if (clipView) {
    let h = clipView.offsetHeight
    let w = Math.ceil(h * (width / height))
    let unitSecond = getDurationByWidth(w) / 1000000
    return { width: w, unitSecond }
  }
}

export function getObjectKeyByValue(
  obj: { [key: string]: any },
  value: any,
): string {
  const str = Object.keys(obj).find(k => {
    const objValue = obj[k]
    if (Array.isArray(objValue)) {
      return obj[k].includes(value)
    } else {
      return obj[k] === value
    }
  })
  if (str === undefined) {
    throw new Error('getObjectKeyByValue: no fond')
  }
  return str
}

export function flattenMessages(nestedMessages: any, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages: any, key: string) => {
    let value = nestedMessages[key]
    let prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

// Calculate file size function (reserve two decimal places), Size is byte size
export function formatFilesize(size: number) {
  if (!size) return '-'
  let num = 1024.0 //byte
  if (size < num) return size + 'B'
  if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + 'K' //kb
  if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + 'M' //M
  if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + 'G' //G
  return (size / Math.pow(num, 4)).toFixed(2) + 'T' //T
}

/**
 * @description                                 format date
 * @param  {Date|String|Number}      time       date object or timestamp
 * @param  {String}                  format     format mode
 * @return {String}                             formatted date string
 */
export function formatDateTime(
  time: number | string | Date,
  format = 'yyyy-MM-dd HH:mm:ss',
) {
  if (!time) return '-'
  let date: Date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        time = parseInt(time)
      } else {
        return '-'
      }
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj: { [key: string]: number } = {
    y: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    H: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
  }
  const time_str = format.replace(/([yMdHms])+/g, (result, key) => {
    const value = formatObj[key]
    return value.toString().padStart(2, '0')
  })
  return time_str
}

export function formatSec(sec: number) {
  let h = Math.floor(sec / 3600)
  let m = Math.floor((sec - h * 3600) / 60)
  let s = Math.floor(sec - h * 3600 - m * 60)
  // @ts-expect-error
  if (h < 10) h = '0' + h
  // @ts-expect-error
  if (m < 10) m = '0' + m
  // @ts-expect-error
  if (s < 10) s = '0' + s
  return `${h}:${m}:${s}`
}

/**
 * Duration convert
 * @param value
 * @returns {string}
 * @test 3599930/59930
 */
export function secToTime(value: number) {
  let time = value
  if (!value) {
    return '--:--:--'
  }
  if (time < 1000) {
    time = 1000
  }
  let sec = parseInt(time.toString()) / 1000
  let min
  let hour = parseInt((sec / 3600).toString())
  if (hour > 0) {
    sec %= 3600
    min = parseInt((sec / 60).toString())
    if (min > 0) {
      sec %= 60
    }
  } else {
    min = parseInt((sec / 60).toString())
    if (min > 0) {
      sec %= 60
    }
  }
  if (sec > 59.5) {
    sec = 0
    min++
    if (min === 60) {
      min = 0
      hour++
    }
  } else if (sec > 59) {
    sec = 59
  } else {
    sec = Number(sec.toFixed(0))
  }
  return unitFormat(hour) + ':' + unitFormat(min) + ':' + unitFormat(sec)
}

export function convertTimeCode(seconds: number) {
  let sec = parseInt(seconds.toString())
  let frame = Math.round((seconds - sec) * 25)
  let min
  // @ts-expect-error
  let hour = parseInt(sec / 3600)
  if (hour > 0) {
    sec %= 3600
    // @ts-expect-error
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  } else {
    // @ts-expect-error
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  }
  return (
    buildNumberString(hour) +
    ':' +
    buildNumberString(min) +
    ':' +
    buildNumberString(sec) +
    ':' +
    buildNumberString(frame)
  )
}

export function frameFromFormatData(formateData: string) {
  const spiltArr = formateData.split(':')
  const seconds = +spiltArr[0] * 60 * 60 + +spiltArr[1] * 60 + +spiltArr[2]
  return seconds * 25 + +spiltArr[3]
}

export function convertToSrtTime(us: number) {
  const seconds = us / 1000000
  // @ts-expect-error
  let sec = parseInt(seconds)
  let ms = Math.round((seconds - sec) * 1000)
  let min
  // @ts-expect-error
  let hour = parseInt(sec / 3600)
  if (hour > 0) {
    sec %= 3600
    // @ts-expect-error
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  } else {
    // @ts-expect-error
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  }
  return (
    buildNumberString(hour) +
    ':' +
    buildNumberString(min) +
    ':' +
    buildNumberString(sec) +
    ',' +
    buildThreeNumberString(ms)
  )
}

export function buildThreeNumberString(number: number) {
  let numberString = ''
  if (number < 100) {
    numberString += '0'
  }
  if (number < 10) {
    numberString += '0'
  }
  return numberString + number.toString()
}

export function buildNumberString(number: number) {
  let numberString = ''
  if (number < 10) {
    numberString += '0'
  }
  return numberString + number.toString()
}

export function isZh() {
  return getLocaleInfo().locale === 'zh-cn'
}

// Extract the uuid from the string, if not return the string before .
export function getUUIDFromStr(str: string): string {
  const reg =
    /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?!.*\1)/i
  const res = str.match(reg)
  return (res ? res[0] : str.split('.').shift()) as string
}

// 21AC7E3B-BEA1-4781-B77F-AC83615455E2.1.animatedsticker
export function getVersionFromStr(str: string) {
  const arr = str.split('.')
  if (arr.length === 3 && /\d+/.test(arr[1])) {
    return arr[1]
  }
  return -1
}

export function isMediaSupportFormat(name: string) {
  let format = name.split('.')
  let suffix = format[format.length - 1].toLowerCase()
  return (
    videoTypes.indexOf(suffix) !== -1 ||
    audioTypes.indexOf(suffix) !== -1 ||
    imageTypes.indexOf(suffix) !== -1
  )
}

export function getMediaFormatType(name: string) {
  if (name === undefined) {
    return 0
  }
  let format = name.split('.').pop()
  if (format) {
    if (videoTypes.indexOf(format.toLowerCase()) !== -1) {
      return 1
    }
    if (audioTypes.indexOf(format.toLowerCase()) !== -1) {
      return 2
    }
    if (imageTypes.indexOf(format.toLowerCase()) !== -1) {
      return 3
    }
  }
  return -1
}

export function getMediaTypeByName(fileName: string) {
  const arr = fileName.split('.').pop()
  const suffix = (<string>arr).toLocaleLowerCase()
  if (videoTypes.includes(suffix)) {
    return 'video'
  } else if (audioTypes.includes(suffix)) {
    return 'audio'
  } else if (imageTypes.includes(suffix)) {
    return 'image'
  } else {
    return 'video'
  }
}

export function download(url: string, options = {} as any) {
  if (/(.xml|.json)$/.test(url)) {
    options = {
      ...options,
      params: { ...options.params, t: Date.now() },
    }
  }
  return axios.get(url, options).then(res => res.data)
}

export function escapeXmlValue(textValue: string) {
  return textValue
    .replaceAll('&', '&amp;')
    .replaceAll('<', '&lt;')
    .replaceAll('>', '&gt;')
    .replaceAll('"', '&quot;')
    .replaceAll("'", '&apos;')
    .replaceAll('\n', '&#10;')
    .replaceAll('\r', '&#13;')
}

export function unescapeXML(content: string) {
  return content.replace(
    /&amp;|&lt;|&gt;|&apos;|&quot;|&#10;|&#13;/g,
    tag =>
    ({
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&apos;': "'",
      '&quot;': '"',
      '&#10;': '\n',
      '&#13;': '\r',
    }[tag] || tag),
  )
}

export function ARGBHexToRGBA(hexValue: string) {
  if (hexValue === '' || hexValue === null || hexValue === undefined) {
    return ''
  }
  if (!hexValue.includes('#')) {
    return hexValue
  }
  let RGBA =
    'rgba(' +
    parseInt('0x' + hexValue.slice(3, 5)) +
    ',' +
    parseInt('0x' + hexValue.slice(5, 7)) +
    ',' +
    parseInt('0x' + hexValue.slice(7, 9)) +
    ',' +
    parseInt('0x' + hexValue.slice(1, 3)) / 255.0 +
    ')'
  return RGBA
}

export function RGBAToNvsColor(rgbaValue: string) {
  if (rgbaValue === '' || rgbaValue === undefined || rgbaValue === null) {
    return new NvsColor(1, 1, 1, 0)
  }
  let rgba = rgbaValue.match(/(\d(\.\d+)?)+/g)
  if (rgba) {
    return new NvsColor(
      parseInt(rgba[0]) / 255.0,
      parseInt(rgba[1]) / 255.0,
      parseInt(rgba[2]) / 255.0,
      Number(rgba[3]) / 1.0,
    )
  } else {
    return new NvsColor(1, 1, 1, 0)
  }
}

export function HexToNvsColor(hexColor: string) {
  if (hexColor === '' || hexColor === null || hexColor === undefined) {
    return new NvsColor(1, 1, 1, 0)
  }
  let r = parseInt('0x' + hexColor.slice(3, 5)) / 255
  let g = parseInt('0x' + hexColor.slice(5, 7)) / 255
  let b = parseInt('0x' + hexColor.slice(7, 9)) / 255
  let a = parseInt('0x' + hexColor.slice(1, 3)) / 255
  return new NvsColor(r, g, b, a)
}

export function NvsColorToRGBA(color: NvsColor) {
  if (!color) {
    console.warn('The color is empty!')
    return 'rgba(0,0,0,0)'
  }
  let r = Math.round(color.r * 255)
  let g = Math.round(color.g * 255)
  let b = Math.round(color.b * 255)
  let a = color.a
  return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')'
}

export function RGBAToHex(rgbaValue: string) {
  if (rgbaValue === '' || rgbaValue === null || rgbaValue === undefined) {
    return ''
  }
  let rgba = rgbaValue.match(/(\d(\.\d+)?)+/g)
  if (rgba) {
    let r = parseInt(rgba[0])
    let g = parseInt(rgba[1])
    let b = parseInt(rgba[2])
    let a = Number(rgba[3])
    let hex =
      '#' +
      ((1 << 8) | (a * 255)).toString(16).slice(1) +
      ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1) // ARGB
    return hex
  } else {
    throw new Error('Non-standard format like: rgba(255,255,255,1)')
  }
}

export function NvsColorToHex(nvsColor: NvsColor) {
  const { r, b, g, a = 1 } = nvsColor
  const hex =
    '#' +
    ((1 << 8) | (a * 255)).toString(16).slice(1) +
    ((1 << 8) | (r * 255)).toString(16).slice(1) +
    ((1 << 8) | (g * 255)).toString(16).slice(1) +
    ((1 << 8) | (b * 255)).toString(16).slice(1)
  return hex
}

/**
 * Frame accurate, get similar frames
 * @param {Number} time time microseconds
 * @returns time microseconds
 */
export function getFrameTime(time: number) {
  return time - (time % (1000000 / 25))
}

export function deepCopy<T>(obj: T): T {
  return cloneDeepWith(obj, (value: any, key: any) => {
    if (key === 'raw') {
      return value
    }
    if (key === 'multiRaw') {
      return { ...value }
    }
  })
}

export function getSelectedTrack() {
  const {
    timelineData: { tracks },
  } = store.getState().project as { timelineData: TimelineData }
  if (tracks === undefined) return null
  for (let i = 0; i < tracks.length; i++) {
    const track = tracks[i]
    if (track.selected) {
      return track
    }
  }
  return null
}

export function getSelectedClip(): {
  clipData: AllClip | null
  trackIndex: number
} {
  const {
    timelineData: { tracks },
  } = store.getState().project as { timelineData: TimelineData }
  if (tracks === undefined)
    return {
      clipData: null,
      trackIndex: -1,
    }
  for (let i = 0; i < tracks.length; i++) {
    const clips = tracks[i].clips
    const trackIndex = i
    for (let j = 0; j < clips.length; j++) {
      const clip = clips[j]
      if (clip.selected) {
        return { clipData: clip, trackIndex }
      }
    }
  }
  return {
    clipData: null,
    trackIndex: -1,
  }
}

export function getRegionValueFromSdkValue(vecRegionInfo: any) {
  if (!vecRegionInfo || vecRegionInfo.size() <= 0) {
    throw new Error('region info is empty')
  }
  const regionInfo = vecRegionInfo.get(0)
  if (regionInfo.type === 'polygon') {
    if (regionInfo.points.size() < 4) {
      // console.warn('region info points is not enough!')
      throw new Error('region info points is not enough!')
    }
    const x1Val = regionInfo.points.get(0).x
    const y1Val = regionInfo.points.get(0).y
    const x2Val = regionInfo.points.get(1).x
    const y2Val = regionInfo.points.get(1).y
    const x3Val = regionInfo.points.get(2).x
    const y3Val = regionInfo.points.get(2).y
    const x4Val = regionInfo.points.get(3).x
    const y4Val = regionInfo.points.get(3).y
    return {
      type: regionInfo.type,
      x1: x1Val,
      y1: y1Val,
      x2: x2Val,
      y2: y2Val,
      x3: x3Val,
      y3: y3Val,
      x4: x4Val,
      y4: y4Val,
    }
  } else if (regionInfo.type === 'ellipse') {
    const centerXVal = regionInfo.center.x
    const centerYVal = regionInfo.center.y
    const aVal = regionInfo.a
    const bVal = regionInfo.b
    const angleVal = regionInfo.theta
    return {
      type: regionInfo.type,
      centerX: centerXVal,
      centerY: centerYVal,
      a: aVal,
      b: bVal,
      angle: angleVal,
    }
  } else {
    throw new Error('unknow type')
  }
}

export function convertRegionValueToEllipseRegion(regionValue: any) {
  let centerXVal = regionValue.x1 + (regionValue.x4 - regionValue.x1) / 2
  let centerYVal = regionValue.y1 - (regionValue.y4 - regionValue.y3) / 2
  let aVal = (regionValue.x4 - regionValue.x1) / 2
  let bVal = (regionValue.y4 - regionValue.y3) / 2
  let angleVal = 0

  let center = new NvsPointF(centerXVal, centerYVal)
  return {
    centerX: centerXVal,
    centerY: centerYVal,
    a: aVal,
    b: bVal,
    angle: angleVal,
    centerXVal,
    centerYVal,
    aVal,
    bVal,
    angleVal,
    center,
  }
}

export function getClipTypeStr(clip: AllClip) {
  if (
    clip.type === 'caption' &&
    (clip as CaptionClip).clipSubType !== 'general'
  ) {
    return clip.type + '-' + (clip as CaptionClip).clipSubType
  }
  if (clip.type === 'timelineVideoFx') {
    if ((clip as TimelineVideoFxClip).clipSubType) {
      if (
        (clip as TimelineVideoFxClip).clipSubType === Enum.mType.effectAdjust
      ) {
        return 'adjust'
      } else {
        return 'mask'
      }
    } else {
      let map = {
        [Enum.mType.videofx]: 'videofx',
        [Enum.mType.effectfx]: 'effectfx',
        [Enum.mType.particle]: 'particle',
      }
      return map[(<TimelineVideoFxClip>clip).nvTypeId]
    }
  }
  return clip.type
}

const EventMap = new Map()
export function setReactEvent(key: string, fn: Function) {
  if (EventMap.has(key)) {
    EventMap.delete(key)
  }
  EventMap.set(key, fn)
}
export function getReactEvent(key: string): Fn {
  if (!EventMap.has(key)) {
    console.warn(`not added this function: ${key}`)
    return () => { }
  }
  return EventMap.get(key)
}

export function compareSdkVersion(version: string, minSdkVersion: string) {
  if (version) {
    let minVersionArray = minSdkVersion.split('.')
    let versionArray = version.split('.')
    if (minVersionArray.length === 3 && versionArray.length === 3) {
      let minVersionArray0 = Number(minVersionArray[0])
      let versionArray0 = Number(versionArray[0])
      if (minVersionArray0 < versionArray0) {
        minSdkVersion = version
      } else if (minVersionArray0 === versionArray0) {
        let minVersionArray1 = Number(minVersionArray[1])
        let versionArray1 = Number(versionArray[1])
        if (minVersionArray1 < versionArray1) {
          minSdkVersion = version
        } else if (minVersionArray1 === versionArray1) {
          let minVersionArray2 = Number(minVersionArray[2])
          let versionArray2 = Number(versionArray[2])
          if (minVersionArray2 < versionArray2) {
            minSdkVersion = version
          }
        }
      }
    }
  }
}

export const blobToBuffer = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = function (result: any) {
      resolve(result.target.result)
    }
    reader.readAsArrayBuffer(blob)
  })
}

export function downloadData(
  url: string,
  responseType?: string,
  callback?: Function,
): Promise<ArrayBuffer> {
  const option: any = responseType ? { responseType } : {}
  if (callback && typeof callback === 'function')
    option.onDownloadProgress = callback
  return new Promise((resolve, reject) => {
    axios
      .get(url, option)
      .then(res => {
        resolve(res.data)
      })
      .catch(reject)
  })
}

export function getKey(clip: VideoClip | AudioClip) {
  return `${clip.id}_${clip.trimIn}_${clip.trimOut}_${!!(clip as VideoClip)
    .separated}_${!!clip.canReplace}`
}

export function sortClip(newClipData: AllClip[]) {
  newClipData = bSort(newClipData)
  for (let j = 0; j < newClipData.length; j++) {
    newClipData[j].index = j
  }
  return newClipData
}

function bSort(arr: AllClip[]) {
  let len = arr.length
  for (let i = 0; i < len - 1; i++) {
    for (let j = 0; j < len - 1 - i; j++) {
      if (arr[j].inPoint > arr[j + 1].inPoint) {
        let temp = arr[j]
        arr[j] = arr[j + 1]
        arr[j + 1] = temp
      }
    }
  }
  return arr
}

export function alignClipIndex(trackInItem: AllTrack) {
  // The index in the clip is unique and sorted by position. Pay attention to the order every time you add it and manually align the index
  for (let j = 0; j < trackInItem.clips.length; j++) {
    trackInItem.clips[j].index = j
  }
}

export function alignTrackIndex(tracks: AllTrack[]) {
  for (let j = 0; j < tracks.length; j++) {
    tracks[j].index = j
  }
}

export function getTrackFirstIndexInTracks(tracks: AllTrack[], type: string) {
  let index = 0
  for (let j = 0; j < tracks.length; j++) {
    let track = tracks[j]
    if (track.type === type) {
      index = j
      break
    }
  }
  return index
}

export function getTrackLastIndexInItems(items: any[], type: string) {
  let index = -1
  for (let j = items.length - 1; j >= 0; j--) {
    let track = items[j]
    if (track.type === type) {
      index = j
      break
    }
  }
  return index
}

export function getTrackDOMHeight(type: string) {
  return type === 'videoTrack' ? 60 : 30
}

// Record the top of the videoClip every time you drag to determine which track you are on when you let go
let tracksTop: any[] = []
export function setTracksTop(className: string, fromTransition: any) {
  let videoTracks = document.getElementsByClassName(className)
  tracksTop.length = 0
  if (!fromTransition) {
    let plottingScale = document.getElementById('timeLineArea-rulerContainer')
    if (plottingScale) {
      tracksTop.push(plottingScale.getBoundingClientRect().top)
    }
  }
  for (let i = 0; i < videoTracks.length; i++) {
    let div = videoTracks[i]
    tracksTop.push(div.getBoundingClientRect().top)
  }
}

export function getTracksTop() {
  return tracksTop
}

export function hasBlankClip() {
  let isExist = false
  const elementList = document.getElementsByClassName(
    'blankClip-box',
  ) as unknown as HTMLDivElement[]
  elementList &&
    elementList.forEach(item => {
      if (!!item.style.display && item.style.display !== 'none') {
        isExist = true
      }
    })
  return isExist
}

export function getImageColorByCanvas(imgSrc: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = imgSrc
    img.crossOrigin = ''
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')!
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0, img.width, img.height)
      const { data } = ctx.getImageData(0, 0, img.width, img.height)
      const color = {
        r: data[0],
        g: data[1],
        b: data[2],
        a: data[3] / 255,
      }
      const colorValue = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
      resolve(colorValue)
    }
    img.onerror = reject
  })
}

export function getImgArrayBuffer(path: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')!
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0, img.width, img.height)
      canvas.toBlob(blob => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.readAsArrayBuffer(blob as Blob)
      })
    }
    img.src = path
    img.onerror = e => {
      console.error(e)
      reject(e)
    }
  })
}

export function getDataUrlByCanvas(imageData: ImageData) {
  let canvas = document.getElementById(
    'webLocalFileCanvas',
  ) as HTMLCanvasElement
  if (!canvas) {
    canvas = document.createElement('canvas')
    canvas.id = 'webLocalFileCanvas'
  }
  canvas.width = imageData.width
  canvas.height = imageData.height
  canvas.getContext('2d')!.putImageData(imageData, 0, 0)
  return canvas.toDataURL()
}

export function removeBaseUrlRigthSlash() {
  let baseUrl = import.meta.env.BASE_URL
  return baseUrl === '/' ? '' : baseUrl
}

export function getLeftBoxWidth() {
  const { leftBoxWidth } = store.getState().app
  return leftBoxWidth || 140 + 550 + 1
}

export function isMac() {
  return /macintosh|mac os x/i.test(navigator.userAgent)
}

export function isWindows() {
  return /windows|win32/i.test(navigator.userAgent)
}

export function isCtrlKey(e: any) {
  return e && ((isMac() && e.metaKey) || (isWindows() && e.ctrlKey))
}

export function isShiftKey(e: any) {
  return e && (e.shiftKey || e.keyCode === 16)
}

export function rgbTransforHexadecimal(rgb: string) {
  const match = rgb.match(/(\d+(\.\d+)?)/g)

  if (match && match.length >= 3) {
    const r = parseInt(match[0], 10)
    const g = parseInt(match[1], 10)
    const b = parseInt(match[2], 10)

    const rHex = r.toString(16).padStart(2, '0')
    const gHex = g.toString(16).padStart(2, '0')
    const bHex = b.toString(16).padStart(2, '0')

    const hexColor = `#${rHex}${gHex}${bHex}`

    return hexColor
  }

  return rgb
}


/**
 * converts timestamp to time ago
 * Case 1: if time < 24hrs ----> 23 mins ago
 * Case 2: if time > 1Hrs and less than 48 Hrs ----> 23 Hrs ago
 * Case 3: if time > 48Hrs --------> on 26 July 2022
 * @param timestamp 
 * @returns string
 */
export const convertTimestampToTimeAgo = (timestamp: number) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const currentTime = Date.now();
  const timeDifference = currentTime - timestamp;
  const minutesAgo = Math.floor(timeDifference / (1000 * 60));
  const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));

  if (minutesAgo < 60) {
    return `${minutesAgo} ${minutesAgo > 1 ? 'mins' : 'min'} ago`;
  } else if (hoursAgo < 48) {
    return `${hoursAgo} ${hoursAgo > 1 ? 'hrs' : 'hr'} ago`;
  } else {
    const date = new Date(timestamp);
    const formattedDate = `${date.toLocaleString('en-US', { day: 'numeric' })} ${months[date.getMonth()]}, ${date.getFullYear()}`;
    return `${formattedDate}`;
  }
}

export const capitalizeWord = (word: string) => {
  if (!word) return '';

  let lowerCase = word.toLocaleLowerCase()
  const firstCapitalWord = lowerCase.charAt(0).toUpperCase();

  return firstCapitalWord + lowerCase.slice(1);
}

export const getOrientationFromAspectRatio = (aspectRatio: string) => {
  if (!aspectRatio) return ''
  const splittedRatio = aspectRatio.split(':')
  const widthRatio = Number(splittedRatio[0]);
  const heightRatio = Number(splittedRatio[1]);

  const ratio = widthRatio / heightRatio

  if (ratio < 1) return 'portrait'
  else if (ratio > 1) return 'landscape'

  return 'square'
}
